import React from 'react';
import { useTranslation } from 'react-i18next';
import Kielenvalinta from '../Kielenvalinta';
import LogoKela from 'kela-design-system/dist/logos/LogoKela';
import LogoFpa from 'kela-design-system/dist/logos/LogoFpa';
import { TitleBar } from 'kela-design-system';

import './styles.css';

export default function Ylapalkki() {
  const { t } = useTranslation();

  const logos = {
    'LogoKela': LogoKela,
    'LogoFpa': LogoFpa
  };

  const logo = React.createElement(logos[t('ylapalkki.logo')], {
    'height': '48',
    'width': '83',
    'aria-label': t('ylapalkki.logoAriaLabel')
  });

  return (
    <TitleBar
      id='ylapalkki'
      title={t('ylapalkki.otsikko')}
      logo={logo}
    >
      <Kielenvalinta />
    </TitleBar >
  );
}
