import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'kela-design-system';
import './styles.css';

export default function OhitusLinkit() {
    const { t } = useTranslation();

    return (
            <Link
              aria-label={t('ohituslinkit.siirry_sisaltoon')}
              className='ohituslinkki'
              href='#sisalto'
            >
                {t('ohituslinkit.siirry_sisaltoon')}
            </Link>
    );
}
