import React, { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';
import DataContext from '../../context/DataContext';
import KirjautumisSivu from '../../views/Kirjautumissivu';
import PalauteLomakeSivu from '../../views/PalautelomakeSivu';
import PalauteLahetettySivu from '../../views/PalauteLahetettySivu';
import VirheSivu from '../../views/Virhesivu';

export default function Nakymat() {
    const { kayttajanTiedot } = useContext(DataContext);
    return (
        <Routes>
            {['/', '/kirjautuminen'].map((route, index) => (<Route key={index} exact path={route} element={<KirjautumisSivu />} />))}
            {kayttajanTiedot.kirjautunut && !kayttajanTiedot.palauteLahetetty && <Route exact path='/palaute' element={<PalauteLomakeSivu />} />}
            {kayttajanTiedot.palauteLahetetty && <Route exact path='/palaute-lahetetty' element={<PalauteLahetettySivu />} />}
            {<Route path='*' element={<VirheSivu />} />}
        </Routes>
    );
}
