import React, { useEffect, useRef, useState, useContext } from 'react';
import DataContext from '../../context/DataContext';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getPalautekehote } from '../../api/Queries';
import log from 'loglevel';
import Ilmoitus from '../../components/Ilmoitus';
import {
  muutaSelaimenMetatietoja,
  onkoObjektiTyhja,
} from '../../utils/helpers';
import {
  InputGroup,
  Input,
  InputText,
  InputLabel,
  Button,
  Spinner,
} from 'kela-design-system';
import IconInfoCircle from 'kela-design-system/dist/icons/ui/IconInfoCircle';
import IconError from 'kela-design-system/dist/icons/ui/IconError';

import './styles.css';

export default function KirjautumisSivu() {
  const { setKayttajanTiedot, latauksenTiedot, setLatauksenTiedot } =
    useContext(DataContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [kirjautumiskoodi, setKirjautumiskoodi] = useState('');
  const [nappiAktiivinen, setNappiAktiivinen] = useState(false);

  const [virhe, setVirhe] = useState(null);
  const [koodiKaytetty, setKoodiKaytetty] = useState(false);
  const inputKirjautumissivuElementti = useRef(null);
  const buttonKirjautumissivuElementti = useRef(null);
  const virheLinkkiElementti = useRef(null);
  const virheIlmoitusElementti = useRef(null);
  const ilmoituksenKohdistusElementti = useRef(null);

  const kirjautumisenMaxLength = 4;
  const kirjautumisenSallitutMerkit = '^[a-zåäöA-ZÅÄÖ0-9]+$';

  useEffect(() => {
    muutaSelaimenMetatietoja(t('kirjautumissivu.title'));
    setKayttajanTiedot(prevState => ({
      ...prevState,
      nakyma: 'kirjautumissivu',
    }));
  }, []);

  useEffect(() => {
    virhe?.kohdistusElementti?.current?.focus();
  }, [virhe]);

  useEffect(() => {
    ilmoituksenKohdistusElementti?.current?.focus();
  }, [koodiKaytetty]);

  const handleInputChange = e => {
    const value = e.target.value;
    const isValid = e.target.validity.valid;

    if (isValid) {
      setKirjautumiskoodi(value);
    }

    setNappiAktiivinen(isValid && value.length === kirjautumisenMaxLength);
  };

  const handleSubmit = async e => {
    log.info('Kirjautusmissivu - handleSubmit');
    e.preventDefault();
    setVirhe(null);
    setKoodiKaytetty(false);

    setLatauksenTiedot(() => ({
      kaynnissa: true,
      valmis: false,
    }));

    await getPalautekehote(kirjautumiskoodi)
      .then(({ data, error }) => {
        if (error) {
          throw Error(error);
        }

        if (onkoObjektiTyhja(data)) {
          setVirhe({
            tyyppi: 'kirjautuminen',
            otsikko: 'kirjautumissivu.kirjautumisvirhe.otsikko',
            linkki: 'kirjautumissivu.kirjautumisvirhe.teksti',
            kohdistusElementti: virheLinkkiElementti,
          });

          return;
        }

        const {
          kirjautumisavain,
          palauteAnnettu,
          lahetyspaiva,
          toimenpide,
          toimipisteId,
          toimipisteNimi,
          sopimusNro,
        } = data;

        setKayttajanTiedot(prevState => ({
          ...prevState,
          kirjautumisavain: kirjautumisavain,
          palauteLahetetty: palauteAnnettu,
          lahetyspaiva: lahetyspaiva,
          toimenpide: toimenpide,
          toimipisteId: toimipisteId,
          toimipisteNimi: toimipisteNimi,
          sopimusNumero: sopimusNro,
        }));

        if (!palauteAnnettu) {
          setKayttajanTiedot(prevState => ({
            ...prevState,
            kirjautunut: true,
          }));
          navigate('/palaute');
          return;
        }

        if (palauteAnnettu) {
          setKayttajanTiedot(prevState => ({
            ...prevState,
            kirjautunut: true,
          }));

          setKoodiKaytetty(true);
          return;
        }

        setVirhe({
          tyyppi: 'kirjautuminen',
          otsikko: 'kirjautumissivu.kirjautumisvirhe.otsikko',
          linkki: 'kirjautumissivu.kirjautumisvirhe.teksti',
          kohdistusElementti: virheLinkkiElementti,
        });
      })
      .catch(error => {
        log.debug(error);
        setVirhe({
          tyyppi: 'yhteys',
          otsikko: 'virhe.virheteksti',
          teksti: 'kirjautumissivu.virhe.teksti',
          kohdistusElementti: virheIlmoitusElementti,
        });
      })
      .finally(() => {
        setLatauksenTiedot(() => ({
          kaynnissa: false,
          valmis: true,
        }));
      });
  };

  return (
    <>
      {virhe && (
        <Ilmoitus
          icon={<IconError className='text-danger icon-size-m' />}
          variant='danger'
        >
          {virhe.otsikko && (
            <strong>
              <p className='mb-1' tabIndex='-1'>
                {t(virhe.otsikko)}
              </p>
            </strong>
          )}

          {virhe.teksti && <p>{t(virhe.teksti)}</p>}

          {virhe.linkki && (
            <a
              ref={virheLinkkiElementti}
              id='kirjautumissivu-error-link'
              href='#'
              onClick={e => {
                e.preventDefault();
                inputKirjautumissivuElementti?.current?.focus();
              }}
              tabIndex='0'
            >
              {t(virhe.linkki)}
            </a>
          )}
        </Ilmoitus>
      )}

      {koodiKaytetty && (
        <Ilmoitus
          icon={<IconInfoCircle className='text-primary icon-size-m' />}
          variant='primary'
        >
          <p className='mb-1' ref={ilmoituksenKohdistusElementti}>
            {t('koodiKaytetty.infoteksti.teksti1')}
          </p>
          <p>{t('koodiKaytetty.infoteksti.teksti2')}</p>
        </Ilmoitus>
      )}

      <h1>{t('kirjautumissivu.otsikko')}</h1>
      <div className='navigointivaihe'>{t('navigointivaihe.teksti')} 1/3</div>
      <p>{t('kirjautumissivu.ohjeteksti')}</p>

      <InputGroup>
        <InputLabel>{t('kirjautumissivu.kirjautumisotsikko')}</InputLabel>
        <p>{t('kirjautumissivu.kirjautumisohje')}</p>

        {virhe?.tyyppi === 'kirjautuminen' && (
          <InputText error id='kirjautumis-error'>
            {t('kirjautumissivu.kirjautumisvirhe.teksti')}
          </InputText>
        )}

        <Input
          aria-invalid={virhe?.tyyppi === 'kirjautuminen'}
          aria-labelledby='kirjautumisohje'
          aria-label={t('kirjautumissivu.kirjautumisohje')}
          id='inputKirjautumissivu'
          ref={inputKirjautumissivuElementti}
          value={kirjautumiskoodi}
          onChange={handleInputChange}
          maxLength={kirjautumisenMaxLength}
          pattern={kirjautumisenSallitutMerkit}
          invalid={virhe?.tyyppi === 'kirjautuminen'}
        />
      </InputGroup>
      <Button
        id='btnKirjautuminen'
        aria-live='off'
        ref={buttonKirjautumissivuElementti}
        className='btn btn-primary'
        disabled={!nappiAktiivinen || latauksenTiedot.kaynnissa}
        iconBefore={latauksenTiedot.kaynnissa ? <Spinner /> : null}
        onClick={handleSubmit}
      >
        {t('kirjautumissivu.nappi')}
      </Button>
    </>
  );
}
