import React, { useRef, useContext } from 'react';
import DataContext from '../../context/DataContext';
import { useNavigate } from 'react-router';
import IdleTimer from 'react-idle-timer';
import log from 'loglevel';

export default function Aikakatkaisija() {
    const { setKayttajanTiedot } = useContext(DataContext);
    const idleTimerRef = useRef(null);
    const navigate = useNavigate(null);
    const AIKAKATKAISUAIKA = 1000 * 60 * 30; // 30 minuuttia

    const onIdle = () => {
        log.info(`Käyttäjä ei ole ollut aktiivinen ${AIKAKATKAISUAIKA / 1000 / 60} minuuttiin -> Siiry kirjautumissivulle`);
        setKayttajanTiedot(prevState => ({
            ...prevState,
            'kirjautunut': false,
            'virhe': false,
            'kirjautumisavain': null,
            'palauteLahetetty': false,
            'palveluntuottajaNimi': '',
            'lahetyspaiva': '',
            'toimenpide': '',
            'toimipisteId': '',
            'toimipisteNimi': '',
            'sopimusNumero': ''
        }));

        navigate('/kirjautuminen');
    };

    return (
        <IdleTimer data-testid='idle-timer-test' ref={idleTimerRef} timeout={AIKAKATKAISUAIKA} onIdle={onIdle} />
    );
}
