import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import DataContext from '../../context/DataContext';
import { TYONKOODI_OMAVAYLA, TYONKOODI_KUNTOUTUSKURSSI } from '../../utils/vakiot';

export default function KuntoutuksenTiedot() {
    const { kayttajanTiedot } = useContext(DataContext);
    const { toimenpide, toimipisteNimi } = kayttajanTiedot;
    const { t } = useTranslation();

    let tyontarkenne = '';
    switch (toimenpide) {
        case TYONKOODI_OMAVAYLA: tyontarkenne = t('tyontarkenne_omavayla');
            break;
        case TYONKOODI_KUNTOUTUSKURSSI: tyontarkenne = t('tyontarkenne_kuntokurssi');
            break;
        default: tyontarkenne = '';
            break;
    }

    return (
        <div>
            <h2>{t('palauteLomake.kuntoutusOtsikko')}</h2>
            <strong><div>{t('palauteLomake.kuntoutuspalvelu')}</div></strong>
            <p>{tyontarkenne}</p>
            <strong><div>{t('palauteLomake.palveluntuottaja')}</div></strong>
            <p>{toimipisteNimi}</p>
        </div>
    );
}
