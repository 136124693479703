import React, { useState, createContext } from 'react';

const DataContext = createContext();

export function DataProvider({ children }) {
    const [kayttajanTiedot, setKayttajanTiedot] = useState({
        'kieli': 'fi',
        'nakyma': null,
        'kirjautunut': false,
        'kirjautumisavain': null,
        'palauteLahetetty': false,
        'lahetyspaiva': '',
        'toimenpide': '',
        'toimipisteId': '',
        'toimipisteNimi': '',
        'sopimusNumero': ''
      });

      const [latauksenTiedot, setLatauksenTiedot] = useState({
        'kaynnissa': false,
        'valmis': false
      });

    return (
      <DataContext.Provider value={{
        kayttajanTiedot,
        setKayttajanTiedot,
        latauksenTiedot,
        setLatauksenTiedot
      }}>
          {children}
      </DataContext.Provider>
  );
}

export default DataContext;
