import React, { useEffect, createRef, useContext } from 'react';
import DataContext from '../../context/DataContext';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import './styles.css';

export default function NavigoinninTulkitsija() {
    const { nakyma } = useContext(DataContext);
    const { t } = useTranslation();
    const titleRef = createRef(null);
    const { pathname } = useLocation(null);

    useEffect(() => {
        if (titleRef.current) {
            titleRef.current.focus();
        }
    }, [pathname]);

    return (
        <>
            {
                nakyma &&
                <div
                  ref={titleRef}
                  className='visually_hidden'
                  tabIndex='-1'
                >
                    {t(`${nakyma}.title`)}
                </div>
            }
        </>
    );
}
