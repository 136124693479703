import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { suoritusYmparisto } from "./api/Queries";
import log from "loglevel";
import { DataProvider } from "./context/DataContext";
import Aikakatkaisija from "./components/Aikakatkaisija";
import Ylapalkki from "./components/Ylapalkki";
import Nakymat from "./components/Nakymat";
import Alapalkki from "./components/Alapalkki";
import NavigoinninTulkitsija from "./components/NavigoinninTulkitsija";
import OhitusLinkit from "./components/Ohituslinkit";
import { Layout } from "kela-design-system";

import "kela-design-system/dist/theme.min.css";
import "./css/rekku.css";

export default function App() {
  useEffect(() => {
    const haeSuoritusYmparisto = async () => {
      try {
        const env = await suoritusYmparisto();
        //Logita tuotannossa vain virheet
        log.setLevel(
          env === "development" || env === "testi" || env === "koulu"
            ? "trace"
            : "error"
        );
      } catch (error) {
        log.debug(error);
      }
    };

    haeSuoritusYmparisto();
  }, []);

  return (
    <DataProvider>
      <BrowserRouter>
        <Layout aria-live="polite">
          <NavigoinninTulkitsija />
          <OhitusLinkit />
          <Aikakatkaisija />
          <Ylapalkki />
          <main id="sisalto" tabIndex="-1">
            <Nakymat />
          </main>
          <Alapalkki />
        </Layout>
      </BrowserRouter>
    </DataProvider>
  );
}
