import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Footer, Link } from 'kela-design-system';
import Valintaikkuna from '../Valintaikkuna';

export default function Alapalkki() {
  const { t } = useTranslation();

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalText, setModalText] = useState({
    'header': null,
    'url': null
  });

  const toggleModal = (e, header, url) => {
    e.preventDefault();
    setModalText({
      header,
      url
    });
    setModalOpen(!isModalOpen);
  };
  const linkkienData = [
    {
      'id': 'tietosuoja',
      'href': t('alapalkki.tietosuoja.linkki'),
      'teksti': t('alapalkki.tietosuoja.teksti')
    },
    {
      'id': 'saavutettavuusseloste',
      'href': t('alapalkki.saavutettavuusseloste.linkki'),
      'teksti': t('alapalkki.saavutettavuusseloste.teksti')
    },
    {
      'id': 'annapalautetta',
      'href': t('alapalkki.annapalautetta.linkki'),
      'teksti': t('alapalkki.annapalautetta.teksti')
    },
    {
      'id': 'asiakaspalvelu',
      'href': t('alapalkki.asiakaspalvelu.linkki'),
      'teksti': t('alapalkki.asiakaspalvelu.teksti')
    }
  ];

  return (
    <Footer id='alapalkki' role='contentinfo' copyrightText={t('alapalkki.copyright')} links={() => (
      <>
        {
          linkkienData.map(linkki => {
            const { id, href, teksti } = linkki;
            return (
              <Link
                key={id}
                href={href}
                onClick={e => toggleModal(e, teksti, href)}
              >
                {teksti}
              </Link>
            );
          })
        }
        <Valintaikkuna isModalOpen={isModalOpen} modalText={modalText} onClick={() => setModalOpen(!isModalOpen)} />
      </>
    )}
    />
  );
}
