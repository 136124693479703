import React, { useContext, useEffect, useRef } from 'react';
import DataContext from '../../context/DataContext';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { muutaSelaimenMetatietoja } from '../../utils/helpers';
import { Card, CardBody, Alert, Button } from 'kela-design-system';
import IconError from 'kela-design-system/dist/icons/ui/IconError';

import './styles.css';

export default function VirheSivu() {
  const { setKayttajanTiedot } = useContext(DataContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { virheElementti } = useRef(null);

  useEffect(() => {
    virheElementti?.current?.focus();
  }, [virheElementti]);

  useEffect(() => {
    muutaSelaimenMetatietoja(t('virhe.title'));
    setKayttajanTiedot(prevState => (
      {
        ...prevState,
        'nakyma': 'virhe'
      }));
  }, []);

  const handleSiirryKyselyynClicked = () => {
    navigate('/kirjautuminen');
  };

  return (
    <Card util='mb-0'>
      <Alert variant='danger' medium util='mb-0'>
        <div className='virhe-error-icon'><IconError className='text-danger icon-size-m ml-3' /></div>
        <div className='virhe-error-text'>{t('virhe.virheteksti')}</div>
      </Alert>
      <CardBody>
        <p ref={virheElementti}>{t('virhe.infoteksti')}</p>
        <Button
          id='btnKyselyyn'
          className='btn btn-primary'
          onClick={handleSiirryKyselyynClicked}>
          {t('virhe.kyselyynNappi')}
        </Button>
      </CardBody>
    </Card>
  );
}
