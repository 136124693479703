import React, { useContext, useEffect } from 'react';
import DataContext from '../../context/DataContext';
import { useTranslation } from 'react-i18next';
import { muutaSelaimenMetatietoja } from '../../utils/helpers';
import { Alert } from 'kela-design-system';
import IconCheck from 'kela-design-system/dist/icons/ui/IconCheck';

import './styles.css';

export default function PalauteLahetettySivu() {
  const { setKayttajanTiedot } = useContext(DataContext);
  const { t } = useTranslation();

  useEffect(() => {
    muutaSelaimenMetatietoja(t('palauteLahetetty.title'));
    setKayttajanTiedot(prevState => (
      {
        ...prevState,
        'nakyma': 'palauteLahetetty'
      }));
  }, []);

  return (
    <>
      <h1>{t('palauteLahetetty.otsikko')}</h1>
      <div className='navigointivaihe'>{t('navigointivaihe.teksti')} 3/3</div>
      <Alert variant='success' util='mb-0'>
        <div className='palautelahetetty-content'>
          <div className='palautelahetetty-icon'>
            <IconCheck />
          </div>
          <div className='palautelahetetty-text'>
            <p>{t('palauteLahetetty.infoteksti.teksti1')}</p>
            <p>{t('palauteLahetetty.infoteksti.teksti2')}</p>
          </div>
        </div>
      </Alert>
    </>
  );
}
