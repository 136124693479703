import axios from 'axios';
import log from 'loglevel';

const URI = process.env.NODE_ENV === 'development' ? 'http://localhost:8080/' : '';

function logitaVirheet(error) {
    if (error.response) {
        log.debug('Response onglema | Status: ', error.response.status);
        return error.response.status;
    } else if (error.request) {
        log.debug('Request onglema ', error.request);
        return error.request;
    }

    log.debug('Virhe ', error.message);
    return error.message;
}

export const getVaittamat = () => axios.get(`${URI}api/vaittamat`)
    .then(result => {
        log.info('/api/vaittamat requesti tehtiin onnistuneesti');
        log.info(result.data);
        return { 'data': result.data };
    })
    .catch(error => {
        log.info('api/vaittamat requesti palautti virheen');
        logitaVirheet(error);
        return { 'error': error };
    });

export const getVastausvaihtoehdot = () => axios.get(`${URI}api/vastausvaihtoehdot`)
    .then(result => {
        log.info('/api/vastausvaihtoehdot requesti tehtiin onnistuneesti');
        log.info(result.data);
        return { 'data': result.data };
    })
    .catch(error => {
        log.info('api/vastausvaihtoehdot requesti palautti virheen');
        logitaVirheet(error);
        return { 'error': error };
    });

export const postVastaukset = data => axios.post(`${URI}api/lahetaVastaukset`, data)
    .then(result => {
        log.info('/api/lahetaVastaukset requesti tehtiin onnistuneesti');
        log.info(result.data);
        return { 'result': result.data };
    })
    .catch(error => {
        log.info('api/lahetaVastaukset requesti palautti virheen');
        logitaVirheet(error);
        return { 'error': error };
    });

export const getPalautekehote = kirjautumiskoodi => axios.get(`${URI}api/palautekehote/${kirjautumiskoodi}`)
    .then(result => {
        log.info('/api/palautekehote requesti tehtiin onnistuneesti');
        log.info(result.data);
        return { 'data': result.data };
    })
    .catch(error => {
        log.info('api/palautekehote requesti palautti virheen');
        logitaVirheet(error);
        return { 'error': error };
    });

export const suoritusYmparisto = () => axios.get(`${URI}api/suoritusymparisto`)
    .then(result => {
        log.info('api/suoritusymparisto requesti tehtiin onnistuneesti');
        log.info(result.data);
        return result.data;
    })
    .catch(error => {
        log.info('api/suoritusymparisto requesti palautti virheen');
        logitaVirheet(error);
        return 'production';
    });
