import React from 'react';
import { Modal, ModalHeader, ModalContent, ModalFooter, Button } from 'kela-design-system';
import { useTranslation } from 'react-i18next';

export default function Valintaikkuna({ isModalOpen, modalText, onClick }) {
    const { t } = useTranslation();

    return (
        <Modal
          appRootId='root'
          variant='primary'
          backdrop={true}
          readContent={true}
          isOpen={isModalOpen}
          toggle={onClick}
        >
            <ModalHeader>{modalText.header}</ModalHeader>
            <ModalContent>
                <p>{t('alapalkki.modalOtsikko')}</p>
            </ModalContent>
            <ModalFooter>
                <Button variant='primary' as='a' href={modalText.url} target='_blank' onClick={onClick}>
                    {t('alapalkki.modal.jatka')}
                </Button>
                <Button variant='primary' outline onClick={onClick}>
                    {t('alapalkki.modal.peruuta')}
                </Button>
            </ModalFooter>
        </Modal>
    );
}
