import React from 'react';
import { Alert } from 'kela-design-system';

export default function Ilmoitus({ children, icon, variant }) {
    return (
        <Alert
          icon={icon}
          variant={variant}
          medium
          util='mt-2'
        >
            {children}
        </Alert>
    );
}
