import React from 'react';
import { Skeleton } from 'kela-design-system';

export default function Luuranko() {
    return (
        <>
            {
                [0, 1, 2, 3, 4].map(index => (
                    <div key={index}>
                        <Skeleton
                          variant='text'
                          util='mb-2 mt-4 p-1 w-50'
                        />
                        <ul>
                            {
                                [0, 1, 2, 3, 4].map(element => (
                                    <div
                                      key={`vaihtoehto_${element}`}
                                      className='d-flex p-1'
                                    >
                                        <Skeleton variant='circle' util='mr-1' />
                                        <Skeleton util='w-25' />
                                    </div>
                                ))
                            }
                        </ul>
                    </div>
                ))
            }
        </>
    );
}
