import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectionGroup, Radio } from "kela-design-system";

import "./styles.css";

export default function Vaittama({
  vaittama,
  vaittamaIndex,
  vastausvaihtoehdot,
  vastaukset,
  setVastaukset,
}) {
  const [virhe, setVirhe] = useState();
  const { i18n, t } = useTranslation();
  const virheteksti = t("palauteLomake.valitse-yksi-vaihtoehdoista");

  const handleChange = (e) => {
    const targetElement = e.target;

    const prevVastaukset = vastaukset;
    prevVastaukset[vaittamaIndex] = {
      vaittamaNro: vaittamaIndex + 1,
      vastausNro: targetElement.value,
    };

    setVastaukset(prevVastaukset);

    setVirhe(false);
  };

  const handleInvalid = (e) => {
    e.preventDefault();
    setVirhe(true);
  };

  const ryhmanOtsikko = `${vaittamaIndex + 1}. ${
    i18n.language === "fi" ? vaittama.vaittamaFi : vaittama.vaittamaSv
  }`;

  return (
    <SelectionGroup
      labelText={ryhmanOtsikko}
      required
      invalid={virhe}
      errorText={virheteksti}
      onChange={handleChange}
      onInvalid={handleInvalid}
      util="mb-4"
      title={t("palauteLomake.valitse-yksi-vaihtoehdoista")}
    >
      {vastausvaihtoehdot.map((vastausvaihtoehto, index) => {
        const { vaihtoehtoNro, vaihtoehtoFi, vaihtoehtoSv } = vastausvaihtoehto;
        const vaihtoehtoTeksti =
          i18n.language === "fi" ? vaihtoehtoFi : vaihtoehtoSv;
        const id = `vaittama-radio-${vaihtoehtoNro}-${vaittamaIndex}`;
        return (
          <Radio
            key={index}
            id={id}
            name={`vaittama-radio-${vaittamaIndex}`}
            labelText={vaihtoehtoTeksti}
            value={vaihtoehtoNro}
            util="mt-2"
          />
        );
      })}
    </SelectionGroup>
  );
}
